<template>
  <section class="presentation">
    <div class="presentation-content">
      <div class="title">
        <h2>Who am I?</h2>
        <p class="title-complement">that's me</p>
      </div>
      <div class="presentation-cards">
        <Card class="presentation-intro">
          <h3 class="with-emoji">🧑🏽 In a few sentences</h3>
          <p>
            I'm <strong>Louan Bengmah</strong>, {{ age }} years old software
            engineer. I'm kind of into anything we can do on the Internet
            (that's a lot of stuff).
          </p>
          <p>
            I use my skills as a <strong>web developer</strong> to create
            useful, aesthetic or fun user-centered websites and programs.
          </p>
          <p>
            I'm pretty active on social medias, such as Twitter and Twitch,
            where I like to share my thoughts and <strong>my creations</strong>.
          </p>
        </Card>
        <div class="cards-container">
          <Card class="presentation-socials">
            <h3 class="with-emoji">🔗 Where to find me?</h3>
            <div class="socials">
              <a
                class="twitter"
                href="https://twitter.com/louanben"
                target="_blank"
              >
                <img src="~@/assets/icons/socials/twitter.svg" alt="Twitter" />
              </a>
              <a
                class="instagram"
                href="https://instagram.com/louan.ben"
                target="_blank"
              >
                <img
                  src="~@/assets/icons/socials/instagram.svg"
                  alt="Instagram"
                />
              </a>
              <a
                class="linkedin"
                href="https://www.linkedin.com/in/louan-bengmah-75196817b/"
                target="_blank"
              >
                <img
                  src="~@/assets/icons/socials/linkedin.svg"
                  alt="LinkedIn"
                />
              </a>
              <a
                class="github"
                href="https://www.github.com/LouanBen"
                target="_blank"
              >
                <img src="~@/assets/icons/socials/github.svg" alt="GitHub" />
              </a>
              <a
                class="twitch"
                href="https://twitch.tv/louanben"
                target="_blank"
              >
                <img src="~@/assets/icons/socials/twitch.svg" alt="Twitch" />
              </a>
              <a
                class="bluesky"
                href="https://bsky.app/profile/louan.bsky.social"
                target="_blank"
              >
                <img src="~@/assets/icons/socials/bluesky.svg" alt="Bluesky" />
              </a>
            </div>
          </Card>
          <a
            class="presentation-cv"
            href="LouanBengmah-Resume.pdf"
            target="_blank"
          >
            <Card>
              <h3 class="with-emoji">📄 Discover my resume</h3>
              <img
                class="cta-arrow"
                src="../assets/icons/cta-arrow-black.svg"
                alt="Discover my resume"
              />
            </Card>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Card from "./utils/Card.vue";

export default {
  name: "Presentation",
  components: {
    Card,
  },
  computed: {
    age: () => {
      const today = new Date();

      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();
      const currentDate = today.getDate();

      if (currentMonth > 9) {
        return currentYear - 2000;
      } else if (currentMonth === 9 && currentDate >= 24) {
        return currentYear - 2000;
      } else {
        return currentYear - 2000 - 1;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
$background-color: #282B5F
$secondary-background-color: #06066B
$primary: #FB9C47

.presentation
  width: 100vw
  display: flex
  scroll-snap-align: start
  scroll-snap-stop: normal
  background: $secondary-background-color
  padding-bottom: 96px
  .presentation-content
    max-width: 1200px
    margin: 0 auto
    padding: 50px 0
    @media (min-width: 2200px)
      max-width: 1800px
    .title
      position: relative
      width: 528px
      margin: auto
      h2
        color: $primary
        font-size: 6em
        display: inline
        position: relative
        z-index: 2
        &::before
          content: "Who am I?"
          font-family: 'Outfit', sans-serif
          color: transparent
          font-size: 1em
          display: inline
          -webkit-text-stroke: 0.2em $secondary-background-color
          color: transparent
          position: absolute
          top: -5px
          left: 0
          z-index: -1
          -webkit-text-decoration: white solid underline
          text-decoration: white solid underline
          text-decoration-thickness: 3.5px
          text-underline-offset: 17px
          width: 101%
      &-complement
        position: absolute
        background: $secondary-background-color
        padding: 0 0 0 10px
        bottom: 0
        right: 64px
        z-index: 3

    p
      max-width: 800px
      margin-top: 4em
    .presentation-cards
      display: flex
      margin-top: 4em
      &>*
        margin: 0 20px
        width: 50%
      .cards-container
        display: flex
        flex-direction: column
        justify-content: space-between
      .card
        //width: 100%
        h3
          color: black
          margin-bottom: 1em
          @media (min-width: 2200px)
            font-size: 1.5em
        p
          color: black
          margin: 0
          margin-bottom: 1em
          max-width: 100%
          strong
            color: black
          @media (min-width: 2200px)
            font-size: 1.5em
        .socials
          display: flex
          flex-wrap: wrap
          a
            margin: 0.25em 1em
            display: block
            width: 48px
            height: 48px
            border-radius: 8px
            overflow: hidden
            img
              width: 100%
            &.twitter
              box-shadow: 0px 2.5px 0px 0px #1D76D3
            &.instagram
              box-shadow: 0px 2.5px 0px 0px #E1306C
            &.linkedin
              box-shadow: 0px 2.5px 0px 0px #00496D
            &.github
              box-shadow: 0px 2.5px 0px 0px #3d3d3d
            &.twitch
              box-shadow: 0px 2.5px 0px 0px #6441A5
            &.bluesky
              box-shadow: 0px 2.5px 0px 0px #005AB7
      .presentation-cv
        .card
          display: flex
          justify-content: space-between
          h3
            margin-bottom: 0
          .cta-arrow
            width: 24px
  @media (max-width: 768px)
    padding-top: 10vh
    .presentation-content
      padding: 0 24px
      .title
          width: 100%
          h2
            font-size: 3rem
            text-align: center
            width: 100%
            display: block
            &::before
              width: 100%
              font-size: 3rem
              -webkit-text-stroke: 0.3em $secondary-background-color
              text-underline-offset: 10px
          &-complement
            display: none
            font-size: 1rem
            bottom: -0.5rem
            line-height: 0.5rem
      .presentation-cards
        flex-direction: column
        &>*
          width: 100%
          margin: 0
        .card
          width: 100%
          margin: 0
          .socials
            a
              margin: 0.5em
              width: 32px
              height: 32px
        .card:not(:last-child)
          margin-bottom: 2em
</style>
