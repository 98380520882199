<template>
  <section class="landing">
    <div class="landing-text">
      <div class="flex-help"></div>
      <div class="text">
        <div class="title">
          <h1>Louan Bengmah</h1>
          <p class="title-complement">aka louanben</p>
        </div>
        <p class="h1-subtitle">Creative</p>
      </div>
      <div class="mouse-anim">
        <div class="wheel-anim"></div>
      </div>
    </div>
    <div class="landing-illustration"></div>
  </section>
</template>

<script>
export default {
  name: "Landing",
};
</script>

<style lang="sass">
$background-color: #282B5F
$primary: #FB9C47

.landing
    height: 100vh
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 0 0 0 40px
    box-sizing: border-box
    scroll-snap-align: start
    scroll-snap-stop: normal
    background: $background-color
    @media (max-width: 768px)
        flex-direction: column
        justify-content: flex-start
        padding: 0
    &-text
        display: flex
        flex-direction: column
        justify-content: space-around
        align-items: center
        height: 70%
        @media (min-width: 2200px)
            padding: 0 0 0 250px
        .text
            display: flex
            flex-direction: column
            align-items: center
            .title
                position: relative
                h1
                    color: $primary
                    font-size: 6em
                    @media (min-width: 2200px)
                        font-size: 8em
                    display: inline
                    position: relative
                    z-index: 2
                    &::before
                        content: "Louan Bengmah"
                        font-family: 'Outfit', sans-serif
                        color: transparent
                        font-size: 1em
                        display: inline
                        -webkit-text-stroke: 0.2em $background-color
                        position: absolute
                        top: -5px
                        left: 0
                        z-index: -1
                        @media (max-width: 350px)
                            top: 0
                            -webkit-text-stroke: 0.5em $background-color
                    &::after
                        content: "Louan Benamah" // le "a" à la place du "g" c'est normal tkt
                        font-family: 'Outfit', sans-serif
                        color: transparent
                        font-size: 1em
                        display: inline
                        position: absolute
                        top: -5px
                        left: 0
                        z-index: -2
                        -webkit-text-decoration: white solid underline
                        text-decoration: white solid underline
                        text-decoration-thickness: 3.5px
                        text-underline-offset: 17px
                        width: 101%
                        animation-name: undeline-animation
                        animation-duration: 1s
                        animation-fill-mode: forwards
                        animation-timing-function: ease-in-out
                        transform: scaleX(0)
                        transform-origin: left
                        @media (max-width: 350px)
                            text-decoration-thickness: 3px
                            text-underline-offset: 10px
                &-complement
                    position: absolute
                    background: $background-color
                    padding: 0 0 0 10px
                    bottom: 0
                    @media (min-width: 2200px)
                        font-size: 1.5em
                    right: -2px
                    z-index: 3
                    color: transparent
                    animation-name: complement-animation
                    animation-duration: 0.5s
                    animation-fill-mode: forwards
                    animation-delay: 0.7s
            .h1-subtitle
                font-size: 4em
                margin-top: 1em
                font-weight: 600
                @media (min-width: 2200px)
                    margin-top: 0.5em
                    font-size: 5em
        @media (max-width: 1380px)
            .text
                .title
                    h1
                        font-size: 5em
                        &::before
                            font-size: 1em
                .h1-subtitle
                    font-size: 3em
        @media (max-width: 768px)
            justify-content: flex-start
            height: auto
            padding-top: 2em
            .text
                align-items: flex-start
                padding-left: 20px
                .title
                    h1
                        font-size: 4em
                        width: 280px
                        &::before
                            font-size: 1em
                            -webkit-text-stroke: 0.3em $background-color
                            text-underline-offset: 12px
                    &-complement
                        display: none
                .h1-subtitle
                    font-size: 3em
                    margin-top: 0.5em
            @media (max-height: 550px)
                .text
                    .title
                        h1
                            font-size: 3em
                            &::before
                                font-size: 1em
                                -webkit-text-stroke: 0.2em $background-color
                                text-underline-offset: 10px
                    .h1-subtitle
                        font-size: 2em
        .mouse-anim
            margin-top: 20px
            width: 70px
            height: 100px
            box-sizing: border-box
            border-radius: 50px
            border: 7px solid white
            position: relative
            .wheel-anim
                width: 10px
                height: 15px
                background: white
                border-radius: 50px
                position: absolute
                top: 20%
                left: calc(50% - 5px)
                animation: wheel-anim 2s infinite
                animation-timing-function: ease-in-out
                @keyframes wheel-anim
                    0%
                        transform: translateY(0)
                    50%
                        transform: translateY(10px)
                    100%
                        transform: translateY(0)
            @media (max-width: 768px)
                display: none
    &-illustration
        height: 100vh
        min-width: 605px
        background: url('../assets/img/louan.png')
        background-size: contain
        background-repeat: no-repeat
        background-position: right
        @media (max-width: 768px)
            min-width: 100%
            width: 100%
        @media (min-width: 2200px)
            min-width: 800px
            background-size: cover

// Animations
@keyframes undeline-animation
    0%
        transform: scaleX(0)
    100%
        transform: scaleX(1)

@keyframes complement-animation
    0%
        color: transparent
    100%
        color: white
</style>
