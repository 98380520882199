import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=2b6d0e28&scoped=true"
var script = {}
import style0 from "./Card.vue?vue&type=style&index=0&id=2b6d0e28&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b6d0e28",
  null
  
)

export default component.exports