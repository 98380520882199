<template>
  <section class="projects">
    <div class="projects-content">
      <div class="title">
        <h2>Projects</h2>
        <p class="title-complement">and we're not done yet</p>
      </div>
      <div
        class="projects-carousel"
        @touchstart="swipeStart"
        @touchmove="swipeMove"
        @touchend="swipeEnd"
      >
        <div class="carousel-window">
          <div
            class="carousel-control left-arrow"
            @click="leftSlide"
            :class="{ inactive: currentSlide <= 1 }"
          >
            <img src="../assets/icons/left-arrow.svg" alt="Next project" />
          </div>
          <div
            class="projects-container"
            :style="{
              width: `${slides.length * (projectWidth + 32)}px`,
              transform: `translateX( calc( (-${projectWidth}px + ${
                projectWidth === 420 ? 24 : 0 // changer 24 en 8 si on retourne à 4 projets (todo rendre ça moins dégeulasse)
              }px) * ${currentSlide - 1} ) )`,
            }"
          >
            <div
              class="project"
              v-for="(slide, index) in slides"
              :key="slide.name"
              :class="[{ current: currentSlide === index + 1 }, slide.class]"
              :style="{
                backgroundImage: `url(${getImageUrl(slide.image)})`,
                boxShadow: `0px 5px 0px 0px ${slide.shadow}`,
              }"
            >
              <div
                class="project-content"
                :style="{ backgroundColor: slide.color }"
              >
                <AnimatedWave :color="slide.color" :delay="index" />
                <div
                  class="project-text"
                  :style="{ backgroundColor: slide.color }"
                >
                  <h3>{{ slide.name }}</h3>
                  <p class="project-description">{{ slide.description }}</p>
                </div>
                <button
                  class="project-cta"
                  @click="openProject(slide)"
                  :style="[
                    {
                      backgroundColor: slide.ctaColor,
                      boxShadow: `0px 3px 0px 0px ${slide.ctaShadow}`,
                    },
                  ]"
                >
                  Discover
                </button>
              </div>
            </div>
          </div>
          <div
            class="carousel-control right-arrow"
            @click="rightSlide"
            :class="{ inactive: currentSlide >= slides.length }"
          >
            <img src="../assets/icons/right-arrow.svg" alt="Previous project" />
          </div>
        </div>
        <div class="carousel-selector">
          <div
            class="carousel-selector-item"
            v-for="(slide, index) in slides"
            :key="slide.name"
            @click="currentSlide = index + 1"
            :class="{ current: currentSlide === index + 1 }"
            :style="{
              backgroundColor:
                currentSlide === index + 1 ? slide.color : 'white',
            }"
          ></div>
        </div>
      </div>
    </div>
    <div class="project-page" :class="{ opened: isProjectOpened }">
      <div
        class="project-page-section"
        :style="{
          backgroundColor: openedProject ? openedProject.ctaColor : '',
        }"
      >
        <button class="close-btn" @click="closeProject()">
          <img src="../assets/icons/close.svg" alt="Close" />
        </button>
        <section
          class="project-page-illustration"
          :style="{
            backgroundImage: openedProject
              ? `url(${getImageUrl(
                  'page-illustrations/' + openedProject.image
                )})`
              : '',
          }"
        >
          <AnimatedWave
            :hasShadow="false"
            :color="openedProject ? openedProject.ctaColor : ''"
            :type="1"
          />
        </section>
        <section class="project-page-content">
          <AnimatedWave
            :color="openedProject ? openedProject.ctaColor : ''"
            :type="2"
          />
          <div
            class="fade-out-mobile"
            :style="{
              background: `linear-gradient(180deg, ${
                openedProject ? openedProject.ctaColor : ''
              } 0%, transparent 100%)`,
            }"
          ></div>
          <div
            class="project-page-text-container"
            :style="{
              backgroundColor: openedProject ? openedProject.ctaColor : '',
            }"
          >
            <div class="project-page-text">
              <h3
                :style="{
                  textShadow: `0px 4px 0px ${
                    openedProject ? openedProject.color : ''
                  }`,
                }"
              >
                {{ openedProject ? openedProject.name : "" }}
              </h3>
              <template v-if="openedProject">
                <div
                  class="project-page-text-content"
                  v-if="openedProject.name === 'Emoji Mashup Bot'"
                >
                  <p>
                    Twitter was a plateform that favored creativity and
                    development among its users. They made available
                    <a href="https://developer.twitter.com/en/docs/twitter-api">
                      a free API
                    </a>
                    to create robots (bot) capable of automating various
                    actions.
                  </p>
                  <p>
                    In July 2019, between my two years of MMI Technical Degree,
                    I decided to learn how to program one myself.
                  </p>
                  <p>
                    By following tutorials on the Internet, I quickly managed to
                    set up a first program. I then needed a concept: it was at
                    this moment that I had the idea of automatically merging
                    Emojis.
                  </p>
                  <p>
                    Every hour, the bot randomly selects two Emojis and then
                    determines the best way to merge them to create a new,
                    exclusive one. It then posts the result on the account.
                  </p>

                  <Tweet
                    v-if="tweetLoaded"
                    class="project-page-tweet"
                    id="1157607071791099904"
                  ></Tweet>

                  <p>
                    While I initially didn't plan on making it an international
                    phenomenon, its success exceeded my expectations! Indeed, in
                    just one week, it already had 120,000 subscribers.
                  </p>

                  <div class="project-page-stats">
                    <Card>
                      <span>Peak at</span>
                      <p>320k</p>
                      <span>followers</span>
                      <div class="stat-icon">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="r-1cvl2hr r-4qtqp9 r-yyyyoo r-yucp9h r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M12.225 12.165c-1.356 0-2.872-.15-3.84-1.256-.814-.93-1.077-2.368-.805-4.392.38-2.826 2.116-4.513 4.646-4.513s4.267 1.687 4.646 4.513c.272 2.024.008 3.46-.806 4.392-.97 1.106-2.485 1.255-3.84 1.255zm5.849 9.85H6.376c-.663 0-1.25-.28-1.65-.786-.422-.534-.576-1.27-.41-1.968.834-3.53 4.086-5.997 7.908-5.997s7.074 2.466 7.91 5.997c.164.698.01 1.434-.412 1.967-.4.505-.985.785-1.648.785z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Card>
                    <Card>
                      <p>+1Bn</p>
                      <span>impressions</span>
                      <div class="stat-icon">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="r-1bwzh9t r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-1hvjb8t r-bnwqim r-1plcrui r-lrvibr"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M12 22c-.414 0-.75-.336-.75-.75V2.75c0-.414.336-.75.75-.75s.75.336.75.75v18.5c0 .414-.336.75-.75.75zm5.14 0c-.415 0-.75-.336-.75-.75V7.89c0-.415.335-.75.75-.75s.75.335.75.75v13.36c0 .414-.337.75-.75.75zM6.86 22c-.413 0-.75-.336-.75-.75V10.973c0-.414.337-.75.75-.75s.75.336.75.75V21.25c0 .414-.335.75-.75.75z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Card>
                    <Card>
                      <p>+20k</p>
                      <span>tweets</span>
                      <div class="stat-icon">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="r-jwli3a r-4qtqp9 r-yyyyoo r-1472mwg r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-lrsllp"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M8.8 7.2H5.6V3.9c0-.4-.3-.8-.8-.8s-.7.4-.7.8v3.3H.8c-.4 0-.8.3-.8.8s.3.8.8.8h3.3v3.3c0 .4.3.8.8.8s.8-.3.8-.8V8.7H9c.4 0 .8-.3.8-.8s-.5-.7-1-.7zm15-4.9v-.1h-.1c-.1 0-9.2 1.2-14.4 11.7-3.8 7.6-3.6 9.9-3.3 9.9.3.1 3.4-6.5 6.7-9.2 5.2-1.1 6.6-3.6 6.6-3.6s-1.5.2-2.1.2c-.8 0-1.4-.2-1.7-.3 1.3-1.2 2.4-1.5 3.5-1.7.9-.2 1.8-.4 3-1.2 2.2-1.6 1.9-5.5 1.8-5.7z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Card>
                  </div>
                  <p>
                    Trending on social media all summer, “Emoji Mashups” were
                    shared by
                    <a
                      href="https://twitter.com/NetflixFR/status/1153982241560645632?s=20&t=uTPeq5H3VWOr13bPkpvsVA"
                      target="_blank"
                    >
                      several major accounts
                    </a>
                    and caught the attention of the international press.
                  </p>
                  <p>
                    The success of this concept lies in the fact that Emoji
                    Mashups, which depict highly specific facial expressions,
                    are widely shared on Twitter, often accompanied by a typical
                    phrase: “My face when…”.
                  </p>
                  <a
                    class="article-banner-cta"
                    href="https://time.com/5663562/emoji-mashup-bot/"
                    target="_blank"
                  >
                    <Card
                      :style="{
                        backgroundImage: `url(${getImageUrl(
                          'illustrations/time-28082019.webp',
                          true
                        )})`,
                      }"
                    >
                      <div class="article-banner-cta-container">
                        <AnimatedWave color="white" :type="1" />
                        <div class="article-banner-cta-content">
                          <img
                            :src="getImageUrl('logos/time.svg', true)"
                            alt="Time"
                          />
                          <p>Discover more in this article!</p>
                          <img
                            class="cta-arrow"
                            src="../assets/icons/cta-arrow-black.svg"
                            alt="Read the article"
                          />
                        </div>
                      </div>
                    </Card>
                  </a>
                  <p>
                    In September 2019, I decided to create a variation of the
                    Emoji Mashup Bot, with a different functionality that allows
                    for more diverse results:
                    <a
                      href="https://twitter.com/EmojiMashupPlus"
                      target="_blank"
                    >
                      Emoji Mashup Plus.
                    </a>
                  </p>
                  <Tweet
                    v-if="tweetLoaded"
                    class="project-page-tweet"
                    id="1175780997406896132"
                  ></Tweet>
                  <p>
                    In April 2020, I published
                    <a
                      href="https://twitter.com/EmojiMashupBot/status/1246179086910869504?s=20&t=uTPeq5H3VWOr13bPkpvsVA"
                      target="_blank"
                    >
                      a website
                    </a>
                    which allows users to generate their own Emoji Mashups.
                  </p>
                  <p>
                    After a few months, the excitment around the account died
                    down, and the bot was no longer as popular as it once was.
                    However, it continued to operate and still generated some
                    attention.
                  </p>
                  <p>
                    In September 2021, the accounts for Emoji Mashup Bot and
                    Emoji Mashup Plus were among the first to be officially
                    recognized by Twitter as “Good Bots.” They were then given
                    the “Automated” label.
                  </p>
                  <p>
                    In February 2022, the platform introduced the option for
                    anyone to obtain this label, provided they met the
                    conditions. On this occasion, the @Twitter account
                    highlighted several influential bots on the network.
                    <a
                      href="https://twitter.com/Twitter/status/1494045784853127178"
                      target="_blank"
                    >
                      A tribute to Emoji Mashup Bot
                    </a>
                    was then featured as the banner of the account for a month.
                  </p>
                  <a
                    class="article-banner-cta"
                    href="https://techno.konbini.com/fr/societe/qui-est-louan-ben-le-nouveau-francais-de-21-ans-sacre-king-ultime-de-twitter/"
                    target="_blank"
                  >
                    <Card
                      :style="{
                        backgroundImage: `url(${getImageUrl(
                          'illustrations/forbes-28072019.png',
                          true
                        )})`,
                      }"
                    >
                      <div class="article-banner-cta-container">
                        <AnimatedWave color="white" :type="1" />
                        <div class="article-banner-cta-content">
                          <img
                            :src="getImageUrl('logos/forbes.svg', true)"
                            alt="Forbes"
                          />
                          <p>Discover more in this article!</p>
                          <img
                            class="cta-arrow"
                            src="../assets/icons/cta-arrow-black.svg"
                            alt="Read the article"
                          />
                        </div>
                      </div>
                    </Card>
                  </a>
                  <p>
                    In November 2022, shortly after Elon Musk's acquisition of
                    Twitter and amid plans to make the platform's API paid, I
                    decided to put an end to the project. The two Emoji Mashup
                    Bot accounts will remain online as long as X (formerly
                    Twitter) allows it, but the account will no longer post new
                    emojis.
                  </p>
                  <Tweet
                    v-if="tweetLoaded"
                    class="project-page-tweet"
                    id="1593443021189685250"
                  ></Tweet>
                  <p>
                    However, in June 2024, I collaborated with Emojipedia, the
                    reference website for emojis, to publish an emoji mashup
                    generator on their site. The project gave a second life to
                    the original idea and allowed users to continue having fun
                    with the different emojis.
                  </p>
                  <Tweet
                    v-if="tweetLoaded"
                    class="project-page-tweet"
                    id="1800574967475552682"
                  ></Tweet>
                  <p>
                    In November 2024, I also decide to clean my old code and to
                    <a
                      target="_blank"
                      href="https://bsky.app/profile/emojimashupbot.bsky.social"
                    >
                      launch it on Bluesky
                    </a>
                    , a new social network with an accessible, open-sourced,
                    API.
                  </p>
                </div>
                <div
                  class="project-page-text-content"
                  v-if="openedProject.name === 'Le Mot (WordleFR)'"
                >
                  <p>
                    En janvier 2022, un jeu du nom de
                    <a
                      target="_blank"
                      href="https://www.nytimes.com/games/wordle/index.html"
                    >
                      Wordle
                    </a>
                    becomes a true worldwide trend on social medias.
                  </p>
                  <p>
                    The concept is simple: each day, players have six attempts
                    to guess the word of the day. With each attempt, when they
                    enter a 5-letter word, the game provides clues to help them
                    find the solution.
                  </p>
                  <p>
                    A green letter is in the correct position in the word to be
                    guessed. A yellow letter is in the word but not in the
                    correct position. A black letter is not in the word at all.
                  </p>
                  <img
                    class="project-page-image"
                    src="../assets/img/wordle.png"
                    alt="Wordle's principal screen"
                  />
                  <p>
                    Wordle became popular thanks to its innovative sharing
                    system, which allows players to show the result of their
                    game without spoiling anyone, using emojis. Many people then
                    shared their results on social media.
                  </p>
                  <Tweet
                    v-if="tweetLoaded"
                    class="project-page-tweet"
                    id="1515981309209980929"
                    :options="{ theme: 'dark' }"
                  ></Tweet>
                  <p>
                    The only downside of this game is that it is only available
                    in its original language, English. However, many French
                    speakers discovered the game and would like to play it in
                    their own language.
                  </p>
                  <p>
                    Having the necessary skills, I decide to develop it live on
                    Twitch.
                  </p>
                  <iframe
                    class="project-page-twitch"
                    :src="
                      `https://clips.twitch.tv/embed?clip=GleamingCarelessAlpacaVoHiYo-UUxukDcLD168i_9y&parent=${domainName}`
                    "
                    allowfullscreen="true"
                    height="378"
                    width="620"
                    title="Twitch clip"
                  ></iframe>
                  <p>
                    After a weekend, a first version is ready and released. My
                    adaptation of the game is well-received from the first few
                    days and becomes a success.
                  </p>
                  <div class="project-page-stats">
                    <Card>
                      <span></span>
                      <p>220k</p>
                      <span>unique users/day</span>
                      <div class="stat-icon">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="r-1bwzh9t r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-1hvjb8t r-bnwqim r-1plcrui r-lrvibr"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M12 22c-.414 0-.75-.336-.75-.75V2.75c0-.414.336-.75.75-.75s.75.336.75.75v18.5c0 .414-.336.75-.75.75zm5.14 0c-.415 0-.75-.336-.75-.75V7.89c0-.415.335-.75.75-.75s.75.335.75.75v13.36c0 .414-.337.75-.75.75zM6.86 22c-.413 0-.75-.336-.75-.75V10.973c0-.414.337-.75.75-.75s.75.336.75.75V21.25c0 .414-.335.75-.75.75z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Card>
                    <Card>
                      <p>10k</p>
                      <span>followers on Twitter</span>
                      <div class="stat-icon">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="r-1cvl2hr r-4qtqp9 r-yyyyoo r-yucp9h r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M12.225 12.165c-1.356 0-2.872-.15-3.84-1.256-.814-.93-1.077-2.368-.805-4.392.38-2.826 2.116-4.513 4.646-4.513s4.267 1.687 4.646 4.513c.272 2.024.008 3.46-.806 4.392-.97 1.106-2.485 1.255-3.84 1.255zm5.849 9.85H6.376c-.663 0-1.25-.28-1.65-.786-.422-.534-.576-1.27-.41-1.968.834-3.53 4.086-5.997 7.908-5.997s7.074 2.466 7.91 5.997c.164.698.01 1.434-.412 1.967-.4.505-.985.785-1.648.785z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Card>
                    <Card>
                      <p>~1.5k</p>
                      <span>shares/day on Twitter</span>
                      <div class="stat-icon">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 24 24"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xml:space="preserve"
                          xmlns:serif="http://www.serif.com/"
                          style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M14.046,2.242L9.898,2.232L9.896,2.232C5.522,2.232 2.096,5.659 2.096,10.034C2.096,14.132 5.282,17.24 9.561,17.404L9.561,21.232C9.561,21.34 9.605,21.518 9.681,21.635C9.823,21.86 10.065,21.982 10.313,21.982C10.451,21.982 10.59,21.944 10.715,21.864C10.979,21.696 17.188,17.724 18.803,16.358C20.705,14.748 21.843,12.388 21.846,10.046L21.846,10.029C21.84,5.662 18.416,2.242 14.046,2.241L14.046,2.242Z"
                            />
                          </g>
                        </svg>
                      </div>
                    </Card>
                  </div>
                  <p>
                    I made the project completely free and open-source.
                    Developers can then contribute to the project by making
                    suggestions, which I either approve or reject.
                  </p>
                  <object
                    class="project-page-github"
                    type="image/svg+xml"
                    data="https://gh-card.dev/repos/louanben/wordle-fr.svg?fullname=&link_target=_blank"
                    title="GitHub repository card for wordle-fr"
                    aria-label="GitHub repository card for wordle-fr"
                  >
                    <a
                      href="https://github.com/louanben/wordle-fr"
                      target="_blank"
                    >
                      GitHub repository card for wordle-fr
                    </a>
                  </object>
                  <p>
                    On March 8, 2022, "Le Mot" recorded its highest number of
                    daily users (240k). That day, for International Women's
                    Rights Day, I organized a special event in collaboration
                    with the agency
                    <a href="https://www.hungryandfoolish.paris/FR/index.html"
                      >Hungry&Foolish</a
                    >
                    and the association
                    <a href="https://www.lesinternettes.com/">Les Internettes</a
                    >The goal of this campaign was to raise awareness among
                    players about women's rights and encourage them to make
                    donations to Les Internettes and other feminist
                    associations.
                  </p>
                  <Tweet
                    v-if="tweetLoaded"
                    class="project-page-tweet"
                    id="1504053117138214915"
                    :options="{ theme: 'dark' }"
                  ></Tweet>
                  <a
                    class="article-banner-cta"
                    href="https://www.facebook.com/M6info/videos/jeu-wordle-lettre-%C3%A0-part/669154264455951/"
                    target="_blank"
                  >
                    <Card
                      :style="{
                        backgroundImage: `url(${getImageUrl(
                          'illustrations/M6-15012022.png',
                          true
                        )})`,
                      }"
                    >
                      <div class="article-banner-cta-container">
                        <AnimatedWave color="white" :type="1" />
                        <div class="article-banner-cta-content">
                          <img
                            :src="getImageUrl('logos/m6.svg', true)"
                            alt="M6"
                          />
                          <p>Discover more in this video!</p>
                          <img
                            class="cta-arrow"
                            src="../assets/icons/cta-arrow-black.svg"
                            alt="Check the video"
                          />
                        </div>
                      </div>
                    </Card>
                  </a>
                </div>
                <!-- <div
                  class="project-page-text-content"
                  v-if="openedProject.name === 'Spotters Media'"
                >
                  <p>
                    “Spotters” est un média étudiant indépendant créé
                    officiellement le 18 avril 2021 par
                    <a href="https://twitter.com/tahzio" target="_blank"
                      >Tahzio</a
                    >, sur Twitter.
                  </p>
                  <p>
                    L’objectif de ce média est de couvrir l’actualité de sujets
                    qui concernent la culture internet, les memes, les tendances
                    et les créateur•rices de contenus sur les réseaux sociaux.
                  </p>
                  <p>
                    On est une équipe d’une quinzaine de jeunes passionnés par
                    ces sujets.
                  </p>
                  <p>
                    Pour parler de tout ça, on utilise le format de thread – une
                    série de plusieurs tweets – sur Twitter. Ainsi, en un an, on
                    s’est imposé dans le monde de la communication, et nous
                    sommes devenus une vraie référence auprès des community
                    managers et des créateurs de contenu.
                  </p>
                  <Tweet
                    v-if="tweetLoaded"
                    class="project-page-tweet"
                    id="1478294294138167298"
                  ></Tweet>
                  <p>
                    Notre passion et notre maîtrise du sujet nous permettent
                    d’être réactifs, et d'offrir des explications claires et
                    précises sur des tendances qui peuvent être mal comprises
                    pour beaucoup de personnes.
                  </p>
                  <p>
                    En plus d’être rédacteur pour ce média, je m’occupe, entre
                    autres d’épauler Tahzio dans l’organisation de l’équipe et
                    du compte Twitter, ainsi que dans la recherche des concepts
                  </p>
                  <div class="project-page-stats">
                    <Card>
                      <span>Plus de</span>
                      <p>9000</p>
                      <span>followers</span>
                      <div class="stat-icon">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="r-1cvl2hr r-4qtqp9 r-yyyyoo r-yucp9h r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M12.225 12.165c-1.356 0-2.872-.15-3.84-1.256-.814-.93-1.077-2.368-.805-4.392.38-2.826 2.116-4.513 4.646-4.513s4.267 1.687 4.646 4.513c.272 2.024.008 3.46-.806 4.392-.97 1.106-2.485 1.255-3.84 1.255zm5.849 9.85H6.376c-.663 0-1.25-.28-1.65-.786-.422-.534-.576-1.27-.41-1.968.834-3.53 4.086-5.997 7.908-5.997s7.074 2.466 7.91 5.997c.164.698.01 1.434-.412 1.967-.4.505-.985.785-1.648.785z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Card>
                    <Card>
                      <span>Plus de</span>
                      <p>13M</p>
                      <span>vues sur Twitter</span>
                      <div class="stat-icon">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="r-1bwzh9t r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-1hvjb8t r-bnwqim r-1plcrui r-lrvibr"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M12 22c-.414 0-.75-.336-.75-.75V2.75c0-.414.336-.75.75-.75s.75.336.75.75v18.5c0 .414-.336.75-.75.75zm5.14 0c-.415 0-.75-.336-.75-.75V7.89c0-.415.335-.75.75-.75s.75.335.75.75v13.36c0 .414-.337.75-.75.75zM6.86 22c-.413 0-.75-.336-.75-.75V10.973c0-.414.337-.75.75-.75s.75.336.75.75V21.25c0 .414-.335.75-.75.75z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Card>
                    <Card>
                      <p>~500</p>
                      <span>thread publiés</span>
                      <div class="stat-icon">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="r-jwli3a r-4qtqp9 r-yyyyoo r-1472mwg r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-lrsllp"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M8.8 7.2H5.6V3.9c0-.4-.3-.8-.8-.8s-.7.4-.7.8v3.3H.8c-.4 0-.8.3-.8.8s.3.8.8.8h3.3v3.3c0 .4.3.8.8.8s.8-.3.8-.8V8.7H9c.4 0 .8-.3.8-.8s-.5-.7-1-.7zm15-4.9v-.1h-.1c-.1 0-9.2 1.2-14.4 11.7-3.8 7.6-3.6 9.9-3.3 9.9.3.1 3.4-6.5 6.7-9.2 5.2-1.1 6.6-3.6 6.6-3.6s-1.5.2-2.1.2c-.8 0-1.4-.2-1.7-.3 1.3-1.2 2.4-1.5 3.5-1.7.9-.2 1.8-.4 3-1.2 2.2-1.6 1.9-5.5 1.8-5.7z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Card>
                  </div>
                  <p>
                    Mais notre projet ne s’arrête pas là. En effet, en septembre
                    2021 nous avons publié notre première vidéo sur Youtube :
                    une interview d'1h, dans laquelle on discute avec Leïla, une
                    créatrice de contenu sur Twitter et Twitch.
                  </p>
                  <p>
                    On cherche actuellement à développer d’autres contenus
                    vidéos dans les mois à venir...
                  </p>
                  <iframe
                    class="project-page-youtube"
                    width="560"
                    height="315"
                    src="https://www.youtube-nocookie.com/embed/q0hHpwO2rTk"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <p>
                    En janvier 2022 on s’est également lancé sur Instagram. À la
                    fois pour toucher une autre audience, en déclinant nos post
                    sur Twitter en post sur Instagram. Mais également afin de
                    tenter de nouveaux formats.
                  </p>

                  <iframe
                    class="project-page-instagram"
                    width="320"
                    height="530"
                    src="https://www.instagram.com/p/CZRlJkTMmbO/embed"
                    frameborder="0"
                  ></iframe>
                  <p>
                    En novembre 2023 le projet devient un vrai média associé à
                    une entreprise. Ayant rejoins l'initiative pour l'aspect
                    bénévole et n'étant pas en capacité de cumuler deux
                    professions, je quitte le projet.
                  </p>
                </div> -->
                <div
                  class="project-page-text-content"
                  v-if="openedProject.name === 'Chaine Twitch'"
                >
                  <p>
                    <a href="https://www.twitch.tv/" target="_blank">Twitch</a>
                    is a live video streaming platform. Originally focused on
                    "gaming," it has now become popular among many other
                    communities. You can find talk shows, similar to television
                    programs; politics; lifestyle content; artistic creation,
                    and much more.
                  </p>
                  <p>
                    I started discovering Twitch much later than most people my
                    age, at the end of 2020. My desire to host live streams was
                    immediate!
                  </p>
                  <p>
                    I launched my channel in December 2020 and quickly became an
                    affiliate, which allowed me to start earning revenue from my
                    streams. I initially began with gaming, and it was
                    well-received! This helped me build a small, regular
                    audience.
                  </p>
                  <div class="project-page-stats">
                    <Card>
                      <span>More than</span>
                      <p>800</p>
                      <span>followers</span>
                      <div class="stat-icon">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="r-1cvl2hr r-4qtqp9 r-yyyyoo r-yucp9h r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M12.225 12.165c-1.356 0-2.872-.15-3.84-1.256-.814-.93-1.077-2.368-.805-4.392.38-2.826 2.116-4.513 4.646-4.513s4.267 1.687 4.646 4.513c.272 2.024.008 3.46-.806 4.392-.97 1.106-2.485 1.255-3.84 1.255zm5.849 9.85H6.376c-.663 0-1.25-.28-1.65-.786-.422-.534-.576-1.27-.41-1.968.834-3.53 4.086-5.997 7.908-5.997s7.074 2.466 7.91 5.997c.164.698.01 1.434-.412 1.967-.4.505-.985.785-1.648.785z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Card>
                    <Card>
                      <span>Almost</span>
                      <p>10k</p>
                      <span>unique viewers</span>
                      <div class="stat-icon">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="r-1bwzh9t r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-1hvjb8t r-bnwqim r-1plcrui r-lrvibr"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M12 22c-.414 0-.75-.336-.75-.75V2.75c0-.414.336-.75.75-.75s.75.336.75.75v18.5c0 .414-.336.75-.75.75zm5.14 0c-.415 0-.75-.336-.75-.75V7.89c0-.415.335-.75.75-.75s.75.335.75.75v13.36c0 .414-.337.75-.75.75zM6.86 22c-.413 0-.75-.336-.75-.75V10.973c0-.414.337-.75.75-.75s.75.336.75.75V21.25c0 .414-.335.75-.75.75z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Card>
                    <Card>
                      <span>Peaked at</span>
                      <p>375</p>
                      <span>viewers</span>
                      <div class="stat-icon">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 24 24"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xml:space="preserve"
                          xmlns:serif="http://www.serif.com/"
                          style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
                        >
                          <g>
                            <path
                              :fill="openedProject.color"
                              d="M16.08,15.06C15.09,14.07 13.68,13.54 12.01,13.54L11.98,13.54C10.36,13.54 8.92,14.09 7.92,15.09C7.05,15.96 6.58,17.11 6.58,18.34C6.58,19.13 7.22,19.78 8.02,19.78L15.97,19.78C16.76,19.78 17.41,19.13 17.41,18.34C17.41,17.08 16.94,15.91 16.08,15.06ZM17.959,16.235C17.711,15.608 17.341,15.038 16.861,14.561C16.404,14.101 15.865,13.731 15.257,13.461C15.079,13.382 14.952,13.221 14.916,13.03C14.881,12.839 14.941,12.643 15.078,12.505C15.079,12.505 15.079,12.505 15.079,12.505C16.009,11.575 17.347,11.064 18.853,11.064L18.881,11.064C20.433,11.064 21.744,11.556 22.664,12.477C23.463,13.267 23.9,14.354 23.9,15.525C23.9,16.2 23.39,16.765 22.737,16.852C22.648,16.87 22.555,16.88 22.46,16.88L18.71,16.88C18.338,16.88 18.016,16.6 17.959,16.235ZM2.137,11.887C2.971,11.348 4.011,11.064 5.189,11.064L5.217,11.064C6.723,11.064 8.061,11.575 8.991,12.505C8.991,12.505 8.991,12.505 8.992,12.505C9.129,12.643 9.189,12.839 9.153,13.03C9.118,13.221 8.99,13.382 8.813,13.461C8.204,13.731 7.666,14.101 7.209,14.561C6.688,15.079 6.297,15.705 6.051,16.395C5.965,16.646 5.743,16.822 5.485,16.854C5.422,16.871 5.357,16.88 5.29,16.88L1.54,16.88C0.793,16.88 0.175,16.285 0.17,15.549C0.17,15.541 0.17,15.533 0.17,15.525L0.17,15.515C0.166,14.355 0.596,13.284 1.38,12.49C1.609,12.261 1.862,12.06 2.137,11.887ZM15.09,9.07C14.84,7.24 13.66,6.09 11.99,6.09C10.33,6.09 9.14,7.24 8.9,9.07C8.72,10.39 8.9,11.33 9.45,11.96C10.11,12.72 11.11,12.82 11.99,12.82C12.88,12.82 13.87,12.72 14.53,11.96C15.09,11.33 15.27,10.39 15.09,9.07ZM2.18,7.78C2.18,8.56 2.38,9.18 2.77,9.63C3.38,10.33 4.3,10.43 5.11,10.43C5.93,10.43 6.84,10.33 7.46,9.62C7.96,9.04 8.14,8.17 7.98,6.97C7.75,5.27 6.64,4.22 5.11,4.22C3.57,4.22 2.47,5.27 2.25,6.97C2.2,7.31 2.18,7.56 2.18,7.78ZM21.75,6.97C21.53,5.27 20.43,4.22 18.89,4.22C17.36,4.22 16.25,5.27 16.02,6.97C15.86,8.17 16.04,9.04 16.54,9.62C17.16,10.33 18.07,10.43 18.89,10.43C19.7,10.43 20.62,10.33 21.23,9.63C21.62,9.18 21.82,8.56 21.82,7.78C21.82,7.56 21.8,7.31 21.75,6.97Z"
                              style="fill-rule:nonzero;"
                            />
                          </g>
                        </svg>
                      </div>
                    </Card>
                  </div>
                  <p>
                    Almost a year later, I wanted to try something new on my
                    channel. In November 2021, I started a web development
                    project live on Twitch. My viewers, most of whom have no
                    experience in development, can watch me work on a project
                    and also participate in the design choices, offering their
                    feedback.
                  </p>
                  <p>
                    I launched this type of live stream without really expecting
                    it to interest people, mainly to motivate myself to work on
                    a personal project for several hours in a row. At the time,
                    I was going through a period of heavy procrastination,
                    lacking both time and motivation. But with this concept, I
                    worked on the same project while hosting my first live
                    stream on the topic, for over 10 hours without a break!
                  </p>
                  <p>
                    I did more live streams like this while creating other
                    projects, including "Le Mot," which I started live, and it
                    really helped me with its design.
                  </p>
                  <p>
                    Today, I don't consider myself a streamer. I don't make a
                    living from my Twitch activity and don't set any obligations
                    for myself. I stream when I feel like it, on topics I enjoy,
                    and for now, I don't aim to take it further.
                  </p>
                  <p>
                    It is, however, an important personal project to me, one
                    that has reignited my interest in audiovisual content—a
                    field I’ve enjoyed since high school, though I still view it
                    as a hobby.
                  </p>
                  <p>
                    In February 2024, I started improving my Twitch channel by
                    looking into Twitch's free API. This API allows devs to
                    create and add interactive elements to their streams, such
                    as commands in the chat and reactive overlays. Using these
                    features, I made an interactive overlay that allows viewers
                    to place Lego Bricks of different colors and "build" my
                    overlay. I used this concept while I was recording myself
                    building a Lego set, and it was a great success!
                  </p>
                  <iframe
                    class="project-page-twitch"
                    :src="
                      `https://player.twitch.tv/?video=2069526751&parent=${domainName}`
                    "
                    allowfullscreen="true"
                    height="378"
                    width="620"
                    title="Twitch clip"
                  ></iframe>
                </div>
              </template>
            </div>
          </div>
        </section>
      </div>
      <AnimatedWave :color="openedProject ? openedProject.ctaColor : ''" />
    </div>
  </section>
</template>

<script>
import AnimatedWave from "./utils/AnimatedWave.vue";
import Card from "./utils/Card.vue";
import { Tweet } from "vue-tweet-embed";

export default {
  name: "Projects",
  components: {
    AnimatedWave,
    Card,
    Tweet,
  },
  computed: {
    projectWidth() {
      return this.windowInnerWidth > 768 ? 420 : this.windowInnerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowInnerWidth = window.innerWidth;
    });
    this.domainName = window.location.hostname;
  },
  data() {
    return {
      domainName: "",
      currentSlide: 1,
      isProjectOpened: false,
      openedProject: null,
      tweetLoaded: false,
      windowInnerWidth: window.innerWidth,
      touchStartX: null,
      slides: [
        {
          name: "Emoji Mashup Bot",
          description:
            "The success story of an automated Twitter account that merges emojis and posts the result every hour.",
          image: "emoji-mashup-bot.png",
          class: "emoji-mashup-bot",
          color: "#1DA1F2",
          shadow: "#1078F2",
          ctaColor: "#0057BE",
          ctaShadow: "#00489C",
        },
        {
          name: "Le Mot (WordleFR)",
          description:
            "My French adaptation of the English game called Wordle.",
          image: "le-mot.png",
          class: "le-mot",
          color: "#3EAA42",
          shadow: "#2E7D32",
          ctaColor: "#2D7730",
          ctaShadow: "#206222",
        },
        {
          name: "Twitch Channel",
          description:
            "Building an audience, sharing my passions, developing my skills in audiovisual content, all while having fun—that’s the idea behind my Twitch channel.",
          image: "twitch.png",
          class: "twitch",
          color: "#7F28FF",
          shadow: "#6D00EA",
          ctaColor: "#5C319B",
          ctaShadow: "#3C107E",
        },
        // {
        //   name: "Spotters Media",
        //   description:
        //     "Un média qui parle de la culture des réseaux sociaux, racontée par des jeunes passionnés.",
        //   image: "spotters.png",
        //   class: "spotters",
        //   color: "#FF5F5E",
        //   shadow: "#D74E35",
        //   ctaColor: "#DA3130",
        //   ctaShadow: "#AD1716",
        // },
      ],
    };
  },
  methods: {
    leftSlide() {
      if (this.currentSlide === 1) {
        //this.currentSlide = this.slides.length
      } else {
        this.currentSlide--;
      }
    },
    rightSlide() {
      if (this.currentSlide === this.slides.length) {
        //this.currentSlide = 1
      } else {
        this.currentSlide++;
      }
    },
    swipeStart(event) {
      this.touchStartX = event.changedTouches[0].clientX;
    },
    swipeMove(event) {
      if (this.touchStartX === null) {
        return;
      }
      const touchEndX = event.changedTouches[0].clientX;
      const xDiff = touchEndX - this.touchStartX;
      if (xDiff > 100) {
        this.leftSlide();
        this.touchStartX = null;
      } else if (xDiff < -100) {
        this.rightSlide();
        this.touchStartX = null;
      }
    },
    swipeEnd() {
      this.touchStartX = null;
    },
    openProject(project) {
      this.openedProject = project;
      this.isProjectOpened = true;
      this.tweetLoaded = true;
      const projectTextElement = document.querySelector(".project-page-text");
      projectTextElement.scrollTo(0, 0);
    },
    closeProject() {
      this.isProjectOpened = false;
      window.setTimeout(() => {
        this.tweetLoaded = false;
      }, 500);
    },
    getImageUrl(filename, isReference) {
      if (isReference) return require(`../assets/img/references/${filename}`);
      return require(`../assets/img/projects/${filename}`);
    },
  },
};
</script>

<style lang="sass" scoped>
$background-color: #282B5F
$secondary-background-color: #06066B
$primary: #FB9C47

.projects
  width: 100vw
  display: flex
  background: $secondary-background-color
  padding-bottom: 96px
  .project-page
    position: fixed
    top: -200vh
    left: 0
    z-index: 1000
    transition: all 1s ease-in-out
    transition-delay: 1s
    width: 100%
    height: 100%
    // min-height: -webkit-fill-available (je sais plus à quoi ça servait mais ça casse tout maintenant)
    @media (max-width: 768px)
        transition-delay: 0.5s
        &>.animated-wave
            margin-top: -10px !important
    .animated-wave
        transform: scaleY(-1)
        margin-top: -70px
        z-index: -1
        position: relative
    &.opened
        top: 0
        transition-delay: 0s
        .project-page-section
            .project-page-illustration
                transform: translateX(0)
                transition-delay: 1s
            .project-page-content
                transform: translateX(0)
                transition-delay: 0.8s
                .project-page-text-container
                    .project-page-text
                        transform: translateX(0)
                        opacity: 1
                        transition-delay: 1.4s
        @media (max-width: 768px)
            .project-page-section
                .project-page-content
                    .project-page-text-container
                        .project-page-text
                            transition-delay: 1s
    .project-page-section
        background: #0057BE
        width: 100%
        height: 100%
        display: flex
        position: relative
        z-index: 1
        .close-btn
            position: absolute
            top: 20px
            right: 30px
            z-index: 1000
            background: transparent
            border: 0
            cursor: pointer
            img
                width: 32px
                height: 32px
        .project-page-illustration
            width: 100%
            background-size: cover
            background-repeat: no-repeat
            transform: translateX(-100%)
            transition: all 0.5s ease-in-out
            transition-delay: 0.6s
            display: flex
            justify-content: flex-end
            align-items: flex-start
            .animated-wave
                width: 120vh
                transform: rotate(-90deg) translateY(-250px) translateX(-65px)
                transform-origin: top right
                z-index: 1
        .project-page-content
            width: 100%
            position: absolute
            height: 100%
            right: 0
            top: 0
            transform: translateX(150%)
            transition: all 1s ease-in-out
            transition-delay: 0.2s
            display: flex
            justify-content: flex-end
            align-items: flex-start
            .fade-out-mobile
                display: none
            &>.animated-wave
                width: 120vh
                transform: rotate(-90deg) translateY(-12vw) translateX(-65px)
                transform-origin: top right
                z-index: 1
                position: relative
            .project-page-text-container
                position: relative
                z-index: 2
                width: 50%
                height: 100%
                background: #0057BE
                .project-page-text
                    padding: 50px 50px 50px 30px
                    width: 100%
                    height: 100%
                    display: flex
                    flex-direction: column
                    align-items: center
                    box-sizing: border-box
                    overflow-y: auto
                    h3
                        width: 100%
                        font-size: 4rem
                        font-weight: black
                        text-shadow: 0px 4px 0px #1DA1F2
                        margin-bottom: 32px
                        @media (max-width: 1456px)
                            font-size: 3.5rem
                        @media (min-width: 2200px)
                            font-size: 5rem
                    .project-page-text-content
                        width: 100%
                        p
                            line-height: 1.5
                            font-size: 1.2rem
                            max-width: 90%
                            margin-bottom: 1em
                            @media (max-width: 1456px)
                                font-size: 1rem
                            @media (min-width: 2200px)
                                font-size: 1.5rem
                            a
                                font-weight: bold
                                text-decoration: none
                                &:hover
                                    text-decoration: underline
                        .project-page-image
                            max-height: 428px
                            border-radius: 8px
                            margin: 1.5em 0
                            max-width: 100%
                        .project-page-tweet
                            margin: 2.5em 0
                            max-width: 100%
                        .project-page-twitch
                            margin: 1.5em 0
                            border-radius: 8px
                            max-width: 100%
                        .project-page-github
                            margin: 1.5em 0
                            border-radius: 8px
                            max-width: 100%
                            @media (max-width: 768px)
                                height: 100px
                        .project-page-youtube
                            margin: 1.5em 0
                            border-radius: 8px
                            max-width: 100%
                        .project-page-instagram
                            margin: 1.5em 0
                            border-radius: 8px
                            max-width: 100%
                            @media (max-width: 768px)
                                width: 100%
                        .project-page-stats
                            display: grid
                            grid-template-columns: repeat(3, 1fr)
                            grid-template-rows: 1fr
                            grid-gap: 20px
                            margin: 2.5em 0
                            .card
                                height: 100%
                                display: flex
                                flex-direction: column
                                justify-content: flex-end
                                padding-bottom: 20px
                                position: relative
                                p, span
                                    color: black
                                    margin: 0
                                span
                                    font-size: 1.5em
                                    @media (max-width: 1456px)
                                        font-size: 1.2em
                                p
                                    font-size: 3em
                                    font-weight: bold
                                    line-height: 1em
                                    @media (max-width: 1456px)
                                        font-size: 2em
                                .stat-icon
                                    width: 32px
                                    height: 32px
                                    position: absolute
                                    top: 12px
                                    right: 12px
                        .article-banner-cta
                            text-decoration: none
                            .card
                                margin: 2.5em 0
                                display: flex
                                justify-content: flex-end
                                height: 120px
                                background-size: contain
                                background-repeat: no-repeat
                                padding: 0
                                overflow: hidden
                                @media (max-width: 768px)
                                    background-position: -35%
                                .article-banner-cta-container
                                    position: relative
                                    box-sizing: border-box
                                    width: 75%
                                    .animated-wave
                                        position: absolute
                                        transform: rotate(-90deg) translateY(-100%)
                                        width: 150px
                                        top: 107px
                                        left: -30px
                                        z-index: 1
                                    .article-banner-cta-content
                                        position: relative
                                        z-index: 2
                                        background: white
                                        height: 100%
                                        width: 100%
                                        padding: 1em
                                        box-sizing: border-box
                                        img
                                            height: 15px
                                            margin-bottom: 0.5em
                                        p
                                            color: black
                                            font-size: 1.3em
                                            font-weight: bold
                                            max-width: 50%
                                            @media (max-width: 1456px)
                                                font-size: 1.1em
                                        .cta-arrow
                                            position: absolute
                                            height: 18px
                                            bottom: 1.5em
                                            right: 2em
            .project-page-text
                    transform: translateX(50px)
                    opacity: 0
                    transition: all 0.5s ease-in-out
                    transition-delay: 0s
        @media (max-width: 768px)
            .close-btn
                top: 23vh
                width: 24px
                height: 24px
                img
                    width: 24px
                    height: 100%
            .project-page-illustration
                transform: none
                background-size: contain
                position: absolute
                top: 0
                left: 0
                width: 100%
                height: 100%
                .animated-wave
                    display: none
            .project-page-content
                margin-top: 25vh
                height: 75%
                width: 100%
                position: relative
                transform: none
                &>.animated-wave
                    position: absolute
                    left: 0
                    top: 25vh
                    transform: translateY(-27vh) scaleX(150%)
                    transform-origin: center
                    width: 100%
                .fade-out-mobile
                    display: block
                    position: absolute
                    top: -10px
                    left: 0
                    background: transparent
                    width: 100%
                    height: 70px
                    z-index: 10
                .project-page-text-container
                    width: 100%
                    .project-page-text
                        padding: 24px
                        h3
                            font-size: 2rem
                            margin-bottom: 1rem
                        .project-page-text-content
                            p
                                font-size: 1rem
                                max-width: 100%
                                a
                                    text-decoration: underline
                            .project-page-stats
                                grid-template-columns: repeat(1, 1fr)
                                padding: 0 24px
                            .article-banner-cta
                                .card
                                    margin: 2em 0
                                    height: 100px
                                    overflow: hidden
                                    .article-banner-cta-container
                                        position: relative
                                        box-sizing: border-box
                                        width: 75%
                                        .animated-wave
                                            position: absolute
                                            transform: rotate(-90deg) translateY(-100%)
                                            width: 150px
                                            top: 107px
                                            left: -30px
                                            z-index: 1
                                        .article-banner-cta-content
                                            position: relative
                                            z-index: 2
                                            background: white
                                            height: 100%
                                            width: 100%
                                            padding: 1em
                                            box-sizing: border-box
                                            img
                                                height: 15px
                                                margin-bottom: 0.5em
                                            p
                                                font-size: 1em
                                                font-weight: bold
                                                max-width: 60%
                                                @media (max-width: 678px)
                                                    font-size: 0.9em
                                            .cta-arrow
                                                position: absolute
                                                height: 18px
                                                bottom: 1.5em
                                                right: 2em
                .project-page-text
                        transform: translateY(-50px)
                        transition-duration: 0.4s
  .projects-content
    width: 100%
    margin: 0 auto
    padding: 50px 0
    .title
        position: relative
        width: 314.4px
        margin: auto
        h2
            color: $primary
            font-size: 6em
            display: inline
            position: relative
            z-index: 2
            &::before
                content: "Projects"
                font-family: 'Outfit', sans-serif
                color: transparent
                font-size: 6rem
                display: inline
                -webkit-text-stroke: 0.2em $secondary-background-color
                color: transparent
                position: absolute
                top: -5px
                left: 0
                z-index: -1
                -webkit-text-decoration: white solid underline
                text-decoration: white solid underline
                text-decoration-thickness: 3.5px
                text-underline-offset: 17px
                width: 101%
        &-complement
            position: absolute
            background: $secondary-background-color
            padding: 0 0 0 10px
            bottom: 0
            right: -50px
            z-index: 3
        @media (max-width: 768px)
            width: 100%
            h2
                width: 100%
                text-align: center
                font-size: 3rem
                display: block
                &::before
                    font-size: 3rem
                    -webkit-text-stroke: 0.3em $secondary-background-color
                    text-underline-offset: 10px
            &-complement
                display: none
                font-size: 1rem
                bottom: -0.5rem
                line-height: 0.5rem
    .projects-carousel
        margin-top: 4em
        width: 100%
        .carousel-window
            min-width: 1px
            max-width: 100%
            padding-left: calc(50vw - 304px + 16px)
            position: relative
            // overflow: hidden
            .carousel-control
                img
                    width: 96px
                &.left-arrow
                    position: absolute
                    top: calc(50% - 21px)
                    left: calc(50% - 324px)
                    cursor: pointer
                    z-index: 5
                    transition: all 0.3s ease-in-out
                    &:hover
                        transform: translateX(-10px)
                &.right-arrow
                    position: absolute
                    top: calc(50% - 21px)
                    right: calc(50% - 324px)
                    cursor: pointer
                    z-index: 5
                    transition: all 0.3s ease-in-out
                    &:hover
                        transform: translateX(10px)
                &.inactive
                    opacity: 0.5
                    cursor: default
                    &:hover
                        transform: translateX(0)
            .projects-container
                display: flex
                align-items: center
                transition: all 0.5s ease-in-out
                height: 333px
                .project
                    background: #6B00FF
                    border-radius: 5px
                    height: 230px
                    width: 420px
                    margin: 16px
                    transition: all 0.5s ease-in-out
                    // filter: brightness(0.7) blur(2px)
                    background-size: contain
                    background-repeat: no-repeat
                    overflow: hidden
                    display: flex
                    flex-direction: column
                    justify-content: flex-end
                    box-shadow: 0px 5px 0px 0px #4800AC
                    box-sizing: border-box
                    opacity: 0.5
                    &.current
                        opacity: 1
                        width: 608px
                        height: 333px
                        // filter: brightness(1) blur(0)
                        .project-content
                            transform: translateY(calc(90% - 14px - 1em))
                            .project-text
                                h3
                                    opacity: 1
                        &:hover
                            .project-content
                                transform: translateY(0)
                                transition-delay: 0.3s
                                .project-text
                                    h3
                                        margin: 0
                                        transform: translateX(0)
                    .project-content
                        padding: 14px 0
                        height: 85%
                        box-sizing: border-box
                        transform: translateY(90%)
                        transition: all 0.3s ease-in-out
                        display: flex
                        flex-direction: column
                        align-items: center
                        position: relative
                        @media (max-width: 350px)
                            padding: 7px 0
                        .animated-wave
                            position: absolute
                            left: 0
                            top: -45%
                            width: 100%
                            z-index: -1
                        .project-text
                            background: #6B00FF
                            display: flex
                            flex-direction: column
                            align-items: center
                            width: 100%
                            transition: all 0.3s ease-in-out
                            h3
                                width: 50%
                                font-size: 1.5em
                                opacity: 0
                                transition: all 0.3s ease-in-out
                                text-align: center
                                @media (max-width: 350px)
                                    font-size: 1.1em
                                    width: 100%
                            .project-description
                                width: 50%
                                margin-top: 18px
                                line-height: 1.5
                                text-align: left
                                @media (max-width: 350px)
                                    font-size: 0.8em
                        .project-cta
                            cursor: pointer
                            width: 30%
                            font-size: 1em
                            font-weight: bold
                            margin-top: auto
                            margin-bottom: 0.5em
                            text-align: center
                            padding: 0.7em 1em
                            border: none
                            border-radius: 3px
                            //transition: all 0.2s ease-in-out
                            &:active
                                transform: translateY(3px)
                                box-shadow: 0px 0px 0px 0px black !important
                    &.emoji-mashup-bot
                        background-color: #00489C
                        .project-content
                            .project-cta
                                &:active
                                    background-color: #00489C !important
                    &.le-mot
                        background-color: #206222
                        .project-content
                            .project-cta
                                &:active
                                    background-color: #206222 !important
                    &.spotters
                        background-color: #AD1716
                        .project-content
                            .project-cta
                                &:active
                                    background-color: #AD1716 !important
                    &.twitch
                        background-color: #3C107E
                        .project-content
                            .project-cta
                                &:active
                                    background-color: #3C107E !important
        .carousel-selector
            margin-top: 32px
            display: flex
            justify-content: center
            .carousel-selector-item
                margin: 0 6px
                width: 12px
                height: 12px
                border-radius: 50px
                background: white
                cursor: pointer
                transition: all 0.3s ease-in-out
                &.current
                    width: 24px
        @media (max-width: 768px)
            .carousel-window
                .carousel-control
                    img
                        width: 64px
                    &.left-arrow
                        left: 2vw
                        //top: 50%
                    &.right-arrow
                        right: 2vw
                        //top: 50%
                    &:hover
                        transform: translateX(0) !important
                .projects-container
                    height: 520px
                    .project, .project.current
                        width: 80vw
                        margin: 0 10vw
                        height: 520px
                        border-radius: 20px
                        .project-content
                            transform: translateY(0)
                            height: 65%
                            .animated-wave
                                top: -25%
                                transform: scaleX(110%)
                            .project-text
                                padding: 0 50px
                                box-sizing: border-box
                                h3
                                    width: 100%
                                .project-description
                                    width: 100%
                            .project-cta
                                width: 70%
</style>
